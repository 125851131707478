import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "./carouselTestemonials.scss"

const TestimonialsCarousel = ({ title, items }) => {
  const [currentItem, setCurrentItem] = useState("01")
  const slides = items.map(item => {
    return (
      <div className="testimonial">
        <div className="testimonial__content">
          <p>
            {item.text}
            <div className="author">—{item.author}.</div>
          </p>
        </div>
        <div class="img_cnt">
          {/* <img src={item.image} /> */}
        </div>
      </div>
    )
  })

  const settings = {
    dots: true,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: "carouselTestimonials",
    afterChange: index => {
      setCurrentItem(state => {
        return (index + 1).toString().padStart(2, "0")
      })
    },
    appendDots: dots => (
      <ul style={{paddingLeft:0}}>
        <li>{currentItem}</li>
        {dots}
        <li>{items.length.toString().padStart(2, "0")}</li>
      </ul>
    ),
    responsive: [
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  }

  return (
    <div className="testimonialSection">
      <h2 style={{ marginBottom: "30px" }}>{title}</h2>
      <Slider {...settings}>{slides}</Slider>
    </div>
  )
}

export default TestimonialsCarousel
